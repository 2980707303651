import { createSlice } from "@reduxjs/toolkit";
import { facilitiesApi } from "../../middleware/facilitiesApi";

const initialState = {
  facility: "",
  searchValue: "",
  allFacilities: [],
  facFireCompSelect: [],
  facilitySelectElements: [],
  floorOptionsSelectByFacility: [],
  addedFacility: {},
  equipmentKindsFacility:[],
  facilitiesForTest:[],
  planPeriods:[],
  facIntNum:[],
  periodsSelect:[]

};

export const facilitiesSlice = createSlice({
  name: "facilities",
  initialState,
  reducers: {
    changeFacilitiesValue(state, action) {
      state.facilitiesValue = action.payload;
    },
    changeFacilitiesSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    changePlanPeriods(state, action) {
      state.planPeriods = action.payload;
    },
    clearAddedFacility(state) {
      state.addedFacility = {};
    },
    clearFloors(state) {
      state.floorOptionsSelectByFacility = [];
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      facilitiesApi.endpoints.getFacility.matchFulfilled,
      (state, { payload }) => {
        state.facility = payload;
      }
    );
    builder.addMatcher(
      facilitiesApi.endpoints.getFacIntNumbers.matchFulfilled,
      (state, { payload }) => {
        state.facIntNum = payload;
      }
    );
    
    builder.addMatcher(
        facilitiesApi.endpoints.getFacilitySelectElements.matchFulfilled,
        (state, { payload }) => {
          state.facilitySelectElements = payload;
        }
    ); 
    builder.addMatcher(
      facilitiesApi.endpoints.getFacilitySelectElementsWithSearch.matchFulfilled,
      (state, { payload }) => {
        state.facilitySelectElements = payload;
      }
  );
  builder.addMatcher(
    facilitiesApi.endpoints.getFacilitiesForTest.matchFulfilled,
    (state, { payload }) => {
      state.facilitiesForTest = payload;
    }
);
    builder.addMatcher(
      facilitiesApi.endpoints.getFacFireCompSelectElements.matchFulfilled,
      (state, { payload }) => {
        state.facFireCompSelect = payload;
      }
    );
    builder.addMatcher(
        facilitiesApi.endpoints.getAllFacilities.matchFulfilled,
        (state, { payload }) => {
          state.allFacilities = payload;
        }
    );
    builder.addMatcher(
      facilitiesApi.endpoints.getEquipmentKindsFacility.matchFulfilled,
      (state, { payload }) => {
        state.equipmentKindsFacility = payload;
      }
    );
    builder.addMatcher(
      facilitiesApi.endpoints.getPlanPeriods.matchFulfilled,
      (state, { payload }) => {
        state.planPeriods = payload;
      }
  );

    builder.addMatcher(
      facilitiesApi.endpoints.getFloorOptionsSelectByFacility.matchFulfilled,
      (state, { payload }) => {
        state.floorOptionsSelectByFacility = payload;
      }
    );
    builder.addMatcher(
      facilitiesApi.endpoints.addFacility.matchFulfilled,
      (state, { payload }) => {
        state.addedFacility = payload;
      }
    );
  },
});

export const facilitiesActions = facilitiesSlice.actions;
export const facilitiesReducer = facilitiesSlice.reducer;
