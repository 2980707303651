import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ListItemText,
  OutlinedInput,
  Tooltip,
  Typography,
  Box
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import selectArrowUp from "../../../image/icons/selectArrowUp.png";
import selectArrowDown from "../../../image/icons/selectArrowDown.png"

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 13;


const SelectSimple = ({
  options,
  label,
  onChange,
  error,
  value,
  itemValue,
  name,
  placeholder,
  disabled = false,
  displayEmpty = false,
  setValue,
  isSpecialEquipmenGroup = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: options?.length>=4 ? (ITEM_HEIGHT+ ITEM_PADDING_TOP) * 4 : isSpecialEquipmenGroup ? (ITEM_HEIGHT+ ITEM_PADDING_TOP) * (options?.length + 1) : (ITEM_HEIGHT+ ITEM_PADDING_TOP) * options?.length,
      },
    },
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Box sx={{ position: "relative" }} className="input_text">
      <InputLabel sx={{ mb: "8px" }}>
        {t(label)}
      </InputLabel>
      <FormControl fullWidth error={!!error && !disabled} {...rest}>

        <Select
          value={(value || "")}
          input={<OutlinedInput placeholder={placeholder} />}
          onChange={onChange}
          open={isOpen}
          onOpen={handleOpen}
          onClose={handleClose}
          IconComponent={() =>
            <Box sx={{ mr: "24px",cursor:"pointer" }} onClick={()=>{setIsOpen((prev)=> !prev)}}>
              {isOpen ? (
                <img src={selectArrowUp} />
              ) : (
                <img src={selectArrowDown} />
              )
              }
            </Box>}
          MenuProps={MenuProps}
          sx={{
            ".MuiSelect-select": { height: 0 },
            ".MuiListItemText-root": { m: 0 },
          }}
          disabled={disabled}
          displayEmpty={displayEmpty}
        >
          {displayEmpty &&
            <MenuItem sx={{ height: ITEM_HEIGHT+ ITEM_PADDING_TOP }} key={"none"} value="">
              {t(placeholder) || "Нет"}
            </MenuItem>

          }
          {options?.map((option, i) => (
            <MenuItem
              key={i}
              value={option?.value || option?.[itemValue] || option?.id || value?.name || option?.name || option || ""}
              sx={{ height: ITEM_HEIGHT+ ITEM_PADDING_TOP, "&:last-child": {borderBottom: 'none'}  }}
            >
              <Tooltip key={i} title={option?.hint || ""}>
                <ListItemText
                  primary={
                    <Typography>
                      {option?.name || option?.regulationName || option || ""}
                    </Typography>
                  }
                />
              </Tooltip>
            </MenuItem>
          ))}
        </Select>

      </FormControl>
    </Box>
  );
};

export default SelectSimple;
