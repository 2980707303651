import { createSlice } from "@reduxjs/toolkit";
import { regulationsApi } from "../../middleware/regulationsApi";
const initialState = {
    regulations:[],
    select_regulations:[]
};

export const regulationsSlice = createSlice({
    name: "regulations",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
          regulationsApi.endpoints.getRegulationsList.matchFulfilled,
          (state, { payload }) => {
            state.regulations = payload;
          }
        );
        builder.addMatcher(
          regulationsApi.endpoints.getRegulationsforSelection.matchFulfilled,
          (state, { payload }) => {
            state.select_regulations = payload;
          }
        );
      },
      
    
});

export const regulationsActions = regulationsSlice.actions;
export const regulationsReducer = regulationsSlice.reducer;
