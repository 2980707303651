import React, { useState } from "react";
import "./styles.css";
import { Box, Tooltip, tooltipClasses } from '@mui/material';
import DonutChart from "../../components/DonutChart";
import TaskStateList from "../../components/TaskStateList/TaskStateList";
import { getDataToChart } from "../../../helpers/helper";
import { styled } from "@mui/material/styles";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export const TimelineThing = ({ rows, isEdit, expandNames,newItems, setData, items, setitems, startDate, endDate }) => {

  const [selectedItemId, setSelectedItemId] = useState({ id: -1, periodsDates: [] });

  const generateMonthsInRange = (start, end) => {
    const months = [];
    let currentDate = new Date(start);

    while (currentDate <= end) {
      let fulldate = currentDate
      months.push({
        fullDate: new Date(currentDate),
        month: currentDate.getMonth(), // 0-11
        year: currentDate.getFullYear(),
        monthName: currentDate.toLocaleString('default', { month: 'long' }),

      });
      // Переход к следующему месяцу
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return months;
  };
  function replaceMonthAndYear(monthYearStr, dateStr) {
    // Преобразуем строку даты в объект Date
    const currentDate = new Date(dateStr);

    // Извлекаем месяц и год из строки
    const [month, year] = monthYearStr.split('-').map(Number);

    // Обновляем месяц и год (месяцы в JavaScript 0-индексированы, т.е. январь - 0)
    currentDate.setMonth(month); // Ставим месяц (вычитаем 1)
    currentDate.setFullYear(year); // Устанавливаем год

    return currentDate;
  }
  const checkforRepit = (prev, newItem) => {
    const index = prev.findIndex(item => item.id === newItem.id);

    if (index !== -1) {
      // Если объект найден, обновляем его данные
      prev[index] = { ...prev[index], ...newItem };
    } else {
      // Если объекта нет, добавляем новый объект в массив
      prev.push(newItem);
    }

    return prev;
  }
  function isDateInRange(dateRanges, targetRange) {

    const targetStart = new Date(targetRange.startDate);
    const targetEnd = new Date(targetRange.endDate);
    // Итерация по каждому диапазону в массиве
    for (const range of dateRanges) {
      const rangeStart = new Date(range.startDate);
      const rangeEnd = new Date(range.endDate);

      // Проверяем, пересекаются ли диапазоны
      if (targetStart < rangeEnd && targetEnd > rangeStart) {
        return  true; // Найдено пересечение
      }
    }
    return false; // Нет пересечения с ни одним диапазоном
  }
  function isDateInRange1(dateRanges, targetRange) {

    const targetStart = new Date(targetRange.startDate);
    const targetEnd = new Date(targetRange.endDate);
    console.log("isDateInRange", dateRanges)
    // Итерация по каждому диапазону в массиве
    for (const range of dateRanges) {
      const rangeStart = new Date(range.startDate);
      const rangeEnd = new Date(range.endDate);

      // Проверяем, пересекаются ли диапазоны
      if (targetStart < rangeEnd && targetEnd > rangeStart) {
        return {startDate:range.startDate,endDate:range.endDate,isGood:true}  // Найдено пересечение
      }
    }
    return {startDate:targetRange.startDate,endDate:targetRange.endDate,isGood:false}; // Нет пересечения с ни одним диапазоном
  }
  const handleDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    console.log("drag1", result)
    setSelectedItemId({ id: -1, periodsDates: [] })
    if (!destination) return; // Если элемент не был перемещен куда-либо

    const sourceGroup = source.droppableId;
    const destinationGroup = destination.droppableId;

    let sourceelements = sourceGroup.split('_');
    sourceelements.pop();
    let sourceId = sourceelements.join('_');
    let destinationelements = destinationGroup.split('_');
    let lastData = destinationelements.pop();
    let destinationId = destinationelements.join('_');

    if (sourceId === destinationId) {

      const sourceItem = items.find((item) => item.id == draggableId);

      if (sourceItem) {
        const itemStartDate = new Date(replaceMonthAndYear(lastData, sourceItem.startDate));
        const itemEndDate = new Date(replaceMonthAndYear(lastData, sourceItem.endDate));
        const limitperiodsDate = sourceItem.periodsDates

        let isGoodToMove = isDateInRange1(limitperiodsDate, { startDate: itemStartDate, endDate: itemEndDate })
        
        if (isGoodToMove?.isGood) {
          const newitem = { equipmentPlanPeriodId: draggableId, startDate: isGoodToMove?.startDate, endDate: isGoodToMove?.endDate }
          console.log("isGoodToMove",sourceItem,lastData,itemStartDate,itemEndDate,isGoodToMove)
          setData(((prevItems) => {
            const itemIndex = prevItems.findIndex(item => item.equipmentPlanPeriodId === draggableId);
            
            if (itemIndex !== -1) {
              // Если элемент с таким id уже существует, обновляем его
              const updatedItems = [...prevItems];
              updatedItems[itemIndex] = newitem;
              return updatedItems;
            } else {
              // Если элемента с таким id нет, добавляем новый
              return [...prevItems, newitem];
            }
          }))
          setitems((prevData) => prevData.map(item =>
            item.id === draggableId ? { ...item, startDate: isGoodToMove?.startDate, endDate: isGoodToMove?.endDate } : item
          ))
        } else {
          console.warn('Объект выходит за пределы установленных лимитов.');
        }
      }

      // Проверим границы limitStartDate и limitEndDate

    }
  };

  const onDragStart = (result) => {
    const { source, draggableId } = result;
    const sourceGroup = source.droppableId;
    let sourceelements = sourceGroup.split('_');
    sourceelements.pop();
    let sourceId = sourceelements.join('_');
    const sourceItem = items.find((item) => item.id == draggableId);
    setSelectedItemId({ id: sourceId, periodsDates: sourceItem?.periodsDates });

  };
  const handleItemClick = (id, item) => {
    console.log("newitem", item)
    setSelectedItemId(selectedItemId === id ? { id: -1, periodsDates: [] } : { id: id, periodsDates: item?.periodsDates });
  };
  const getEquipmentCount = (groupId, month, year,inPeriod) => {
    const targetData = items.filter(item => {
      const itemStartDate = new Date(item.startDate);
      return item.group === groupId &&
        !expandNames?.includes(item?.group) &&
        itemStartDate.getFullYear() === year &&
        itemStartDate.getMonth() === month;
    });

    if (targetData.length > 0) {
      if (targetData[0]?.jobChart) {
        const data = getDataToChart({
          jobChart: targetData[0]?.jobChart,
          action: null,
        }) || {};
        const taskstates = data?.filter(
          (task) =>
            task.name != "totalJobsAmount" && task.name != "total_jobs_amount"
        );

        const text = data?.filter(
          (task) =>
            task.name == "totalJobsAmount" || task.name == "total_jobs_amount"
        )[0]?.value;

        const HtmlTooltip = styled(({ className, ...props }) => (
          <Tooltip {...props} classes={{ popper: className }} />
        ))(() => ({
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "var(--white)",
            p: "10px",
          },
        }));
        const isExpired = new Date(targetData[0]?.endDate) < new Date()
        return (
          <HtmlTooltip
            arrow
            placement="left-start"
            title={
              <TaskStateList
                data={taskstates || []}
                fontSize="12px"
                lineHeight="18px"
                height="8px"
                width="8px"
              />
            }
          >
            <Box sx={{ filter: isExpired ? 'grayscale(100%)' : 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <DonutChart
                data={taskstates || []}
                width={46}
                height={46}
                radius={23}
                thickness={6}
                isText={text || "0"}
              />
            </Box>
          </HtmlTooltip>
        );
      } else {

        console.log("selectedItemId?.periodsDates", `${groupId}_${month}-${year}`)
        return (
          <Droppable type={groupId} direction='horizontal' style={{}} droppableId={`${groupId}_${month}-${year}`} key={`${groupId}_${month}-${year}`}>
            {(provided) => (
              <Box
                //  key={`${groupId}_${month}-${year}`}
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{ display: "flex", width: "calc(100% - 20px)", ml: "10px", height: "40px" }}
              >
                {targetData?.map((target, index) => {
                  // Ваша функция проверки ограничений
                  return (

                    <Draggable draggableId={target.id} index={index} isDragDisabled={(!target?.isReadOnly && isEdit) ? false : true}>
                      {(provided) => (
                        <Tooltip target={target.id} title={target.regulationName}>
                          <Box
                            key={target.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            // onClick={() => handleItemClick(groupId,target)}
                            flex={1}
                            style={{ ...provided.draggableProps.style }}
                            sx={{

                              backgroundColor: target.color,
                              border: `2px solid ${target.borderColor}`,
                              borderRadius: "8px",
                              borderRight: index != targetData.length - 1 ? 0 : "2px",
                              borderLeft: index != 0 ? 0 : "2px",
                              borderTopRightRadius: index != targetData.length - 1 ? 0 : "8px",
                              borderBottomRightRadius: index != targetData.length - 1 ? 0 : "8px",
                              borderTopLeftRadius: index != 0 ? 0 : "8px",
                              borderBottomLeftRadius: index != 0 ? 0 : "8px",
                              filter: target?.isReadOnly ? 'grayscale(100%)' : 'none',
                            }}
                            //onClick={() => handleItemClick(groupId, target)}
                          >

                          </Box>
                        </Tooltip>
                      )}
                    </Draggable>

                  );
                })}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        )
      }

    }

   return <Droppable type={groupId} direction='horizontal' style={{}} droppableId={`${groupId}_${month}-${year}`} key={`${groupId}_${month}-${year}`}>
      {(provided,snapshot) => (
        <Box
          //  key={`${groupId}_${month}-${year}`}
          ref={provided.innerRef}
          {...provided.droppableProps}
          sx={{backgroundColor: !inPeriod ? "transparent" :  snapshot.isDraggingOver ? 'lightblue' : 'white', display: "flex", width: "calc(100% - 20px)", ml: "10px", height: "40px" }}
        >
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  };
  const monthsInRange = generateMonthsInRange(new Date(startDate), new Date(endDate));
  console.log("monthsInRange", monthsInRange)
  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={handleDragEnd}>
      
      {rows.map(row => {
        const isInSelectedRow = selectedItemId?.id == row.id || selectedItemId?.id == -1
        return <Box

          display="flex"
          key={row.id}
          sx={{}}
        >

          {monthsInRange.map(({ month, year, fullDate }) => {
            let inPeriod = isDateInRange(selectedItemId?.periodsDates, { startDate: fullDate, endDate: fullDate })
            if (selectedItemId?.periodsDates?.length == 0) {
              inPeriod = true
            }

            return <Box
              key={`${row.id}_${month}-${year}`}
              flexGrow={1}
              border={0.5}
              sx={{ height: "58px", width: "96px", alignContent: "center", backgroundColor: (inPeriod && isInSelectedRow) ? "white" : "rgba(128,128,128,0.7)", }}
              borderColor={(inPeriod && isInSelectedRow) ? "#cfd9e8": "rgba(128,128,128,0.7)"}
              textAlign="center"

            >
              {getEquipmentCount(row.id, month, year,inPeriod && isInSelectedRow)}

            </Box>


          })}
        </Box>
      })}

    </DragDropContext>
  );
};
