import { Box, Input, Typography, Button, Checkbox } from '@mui/material'
import { TreeItem2Checkbox, TreeItem2Content, TreeItem2GroupTransition, TreeItem2Icon, TreeItem2IconContainer, TreeItem2Label, TreeItem2Provider, TreeItem2Root } from '@mui/x-tree-view'
import { useTreeItem2 } from '@mui/x-tree-view/useTreeItem2/useTreeItem2'
import React from 'react';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import s from './AddEquipmentLite.module.css';
import { getEquipmentIcon } from '../../../../helpers/getEquipmentIcon'
import Draggable from '../../Equipments/ImageSchema/Draggable'
import { DragOverlay } from '@dnd-kit/core'
import EditIcon from '@mui/icons-material/Edit';
import checkImagechecked from "../../../../image/icons/checkboxChecked.png"
import checkImage from "../../../../image/icons/checkBox.png"

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  const { id, minScale, scale, itemId,EditMode, item, label, activeId, disabled, children, isGroup,isSystem, countNonPlaced, equipIndex, countPlaced, equipmentTypeId, equipmentId, deleteEquip, canEdit, editEquip, canDelete, hasPlacement, onAddEquipment, isForEdit, onChangeName, onOpenEditReglament, isKind, placedItems, ...other } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...getRootProps(other)}>
        <TreeItem2Content {...getContentProps()}  style={{}}>

          <Box sx={{ flexGrow: 1, display: 'flex',placeItems: "center", height: isSystem ? "51px" :"52px",padding:"0px", borderBottom: isSystem ? "1px solid #cfd9e8" : "0px" }}>

            {equipIndex &&
              <Typography sx={{ alignContent: "center",marginRight:"5px", fontFamily: "var(--Inter)", color: "#051d39" }}>{`${equipIndex}. `}</Typography>
            }
            {
              isForEdit && (
                <Input onClick={(e) => {
                  e.stopPropagation();
                }} onChange={(e) => onChangeName(e)} value={label} />
              ) || (
                <TreeItem2Label sx={{ alignContent: "center", fontFamily: "var(--Inter)", color: "#051d39" }} {...getLabelProps()} />
              )
            }
            {
              !!item?.number && (
                <Typography sx={{ alignContent: "center", flexBasis: '15%', fontFamily: "var(--Inter)", color: "#051d39", margin: "0px 24px" }}>{item.number}</Typography>
              )
            }

            {/*isGroup && <Box sx={{ display: 'flex', }}>
              <Typography sx={{ color: "green" }}>{countPlaced}</Typography>
              <Typography>/</Typography>
              <Typography sx={{ color: "red" }}>{countNonPlaced}</Typography>
            </Box>
            */}
            <TreeItem2IconContainer {...getIconContainerProps()} sx={{ alignSelf: "center" }}>
              <TreeItem2Icon status={status} />
            </TreeItem2IconContainer>

          </Box>

        </TreeItem2Content>
        {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

export default CustomTreeItem