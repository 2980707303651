import React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import "./styles/styles.css";

export const SignatureCell = ({ item }) => {
  const { t } = useTranslation();

  const { userFullName, fullName, userLogin, login, signatureDateTime } = item || {};
  const dateFormat = "DD.MM.YYYY, HH:mm:ss";
  const content = dayjs(signatureDateTime).format(dateFormat);

  return (
    <Box className="signature_block">
      <Box>
        <Typography className="signature_block-text">{t("signature:label")}</Typography>
        <Typography className="signature_block-text">{userFullName || fullName || ""}</Typography>
      </Box>

      <Box className="block_inline">
        <Typography className="signature_block-text">{`${t("signature:login")}:`}</Typography>
        <Typography className="signature_block-text">{userLogin || login}</Typography>
      </Box>

      <Box className="block_inline">
        <Typography className="signature_block-text">{`${t("signature:date")}:`}</Typography>
        <Typography className="signature_block-text">{content || ""}</Typography>
      </Box>
    </Box>
  );
};