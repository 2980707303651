import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { PATHS } from "../../../../constants";
import { getRequestDataFilesForAdd, getRequestData } from "../AddReglament/helpers";
import { useAction } from "../../../../hooks/actions";
import BasicForm from "../BasicForm";
import { useManipulateFilesMutation } from "../../../../middleware/apiUploadFile";
import { useLazyGetRegulationQuery, useUpdateRegulationsMutation } from "../../../../middleware/regulationsApi";
const EditReglamentPage = ({ reglamentData, onClose, style }) => {
  const { id, regulationId, protectionObjectId, equipmentKindId, equipmentTypeId, equipmentGroup } = useParams();
  const { changeTitlePage, setIsErrorRequest, setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [getRegulation, { isLoading: isLoadingRegulation, error: errorGetRegulaion }] = useLazyGetRegulationQuery();
  const [EditRegulation, { error: isErrorEdit, isSuccess: isSuccessEdit }] = useUpdateRegulationsMutation();
  const [manipulateFiles, { isError: isErrorFile, isSuccess: isSuccessFile }] = useManipulateFilesMutation();
  const [regulationResponse, setRegulationResponse] = useState();

  const getEquipmentGroup = (equipmentGroup) => {
    return (equipmentGroup && equipmentGroup !== "null" && equipmentGroup) || '';
  };

  useEffect(() => {
    if (id && regulationId && protectionObjectId && equipmentKindId && equipmentTypeId && equipmentGroup) {
      const searchParams = `&Id=${id}&RegulationId=${regulationId}&ProtectionObjectId=${protectionObjectId}&EquipmentKindId=${equipmentKindId}&EquipmentTypeId=${equipmentTypeId}&EquipmentGroupId=${getEquipmentGroup(equipmentGroup)}`;
      getRegulation(searchParams)
        .then((res) => {
          setRegulationResponse(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, regulationId, protectionObjectId, equipmentKindId, equipmentTypeId, equipmentGroup]);

  const regulation = reglamentData || regulationResponse;

  const isError = isErrorEdit || isErrorFile;
  const isSuccess = isSuccessEdit && isSuccessFile;

  const onSubmit = async (data) => {
    const { entities, techcardFiles } = getRequestData({ data, id: regulation.id });

    EditRegulation(entities).then((result) => {
      if ("error" in result) {
        return;
      }
      const requestDataFiles = getRequestDataFilesForAdd(regulation.id, techcardFiles);

      manipulateFiles(requestDataFiles).then((response) => {
        if ("error" in response) {
          return;
        }
      });
      if (onClose) {
        onClose();
      } else {
        navigate(PATHS.EQUIPMENT_REGLAMENTS);
      }
    });
  };

  const handleCancel = () => navigate(PATHS.EQUIPMENT_REGLAMENTS);

  useEffect(() => {
    regulation && changeTitlePage(regulation?.name || "");
  }, [regulation, changeTitlePage]);

  useEffect(() => () => changeTitlePage(""), [changeTitlePage]);

  useEffect(() => {
    setIsErrorRequest(errorGetRegulaion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorGetRegulaion]);

  useEffect(() => {
    setIsErrorSubmit(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Box className="object">
      <Box className="template_page-header">
        <Typography className="title-page">{regulation?.name || ""}</Typography>
        <Box className="template_page-header-buttons">
          <Button className="btn btn-outlined-grey" onClick={onClose || handleCancel}>
            {t("button:comeback")}
          </Button>
          <Button form="editRegulationForm" className="btn btn-primary-fill" type="submit" disabled={isSuccess}>
            {t("button:save")}
          </Button>
        </Box>
      </Box>
      {!isLoadingRegulation && !!regulation ? (
        <BasicForm regulation={regulation} onSubmit={onSubmit} style={style} isErrorRequest={errorGetRegulaion} />
      ) : (
        <Typography className="object-content-empty_text">{t("text_empty_page:edit_reglament")}</Typography>
      )}
    </Box>
  );
};

export default EditReglamentPage;
