import React, { useState, useMemo, useEffect } from "react";
import { useAppSelector } from "../../../../hooks/redux";
import SelectGrouping from "../../../components/SelectGrouping";
import SelectSimple from "../../../components/SelectSimple";
import SelectSimpleSearch from "../../../components/SelectSimple/SelectSimpleSearch";


export const RegulationList = ( props ) => {
  const { select_regulations } = useAppSelector(
    (state) => state.regulations
  );
  const { searchReguLation, setsearchReguLation } = props
  console.log("select_regulations",select_regulations)
  return (
    <SelectSimpleSearch
      
      sx={{ ".MuiTypography-root": { padding: 0 } }}
      searchValue={searchReguLation}
      setSearchValue={setsearchReguLation}
      options={[{value:"все",name:"все"},...select_regulations]}
      {...props}
    />
  );
};

export const SelectSimpleObject = (props) => {
  const { facilitySelectElements } = useAppSelector(
    (state) => state.facilities
  );
  const { searchObject, setsearchObject } = props
  //console.log("options",options)
  return (
    <SelectGrouping

      setSearchValue={setsearchObject}
      searchValue={searchObject}
      options={[...facilitySelectElements]}
      //bgcolor={bgcolor}
      {...props}
    />
  );

};
export const SelectSimpleFloor = (props) => {
  const { floorOptionsSelectByFacility } = useAppSelector(
    (state) => state.facilities
  );

  return <SelectSimple options={floorOptionsSelectByFacility} {...props} />;
};

