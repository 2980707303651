import React, { useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { Box,  Typography, Tooltip } from "@mui/material";
import SelectGrouping from "../../components/SelectGrouping";
import { dateFormat, dateFormatWithTimeWithoutA } from "../../../constants";
import { useGetFacilitySelectElementsWithSearchQuery } from "../../../middleware/facilitiesApi";
import { useGetRegulationsPeriodsQuery, useLazyGetStandardRegulationsQuery } from "../../../middleware/regulationsApi";
import { getEquipmentTypeIdOptionsGroupAddEquip } from "../Equipments/helpers";
import {
  useLazyGetEquipmentSelectElementsQuery,
  useGetEquipmentSystemsQuery,
  useLazyGetEquipmentGroupsQuery,
} from "../../../middleware/equipmentsApi";
import SelectMultipleChipDeletable from "../../components/SelectMultipleChipDeletable";
import { PATHS } from "../../../constants";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import SelectSimpleSearch from "../../components/SelectSimple/SelectSimpleSearch";
import SelectSimple from "../../components/SelectSimple";
import { useAppSelector } from "../../../hooks/redux";
import { getEquipmentOptions } from "../ResponsibleOrganizationsList/helpers";
import { formFields } from "./AddReglament/options";

export const SelectSimpleFloor = (props) => {
  const { floorOptionsSelectByFacility } = useAppSelector((state) => state.facilities);
  let resoption = [{ value: "все", name: "все" }, ...floorOptionsSelectByFacility];

  return <SelectSimpleInbuilt defaultValue={"все"} options={resoption} {...props} />;
};
export const SimpleTextWithTooltipCellDetailes = (props) => {
  const content = props;

  return (
    <Tooltip
      placement="left"
      componentsProps={{
        tooltip: {
          sx: {
            maxHeight: "300px",
            overflowY: "auto",
          },
        },
      }}
      title={content?.row?.workRequirementsDetails}
    >
      <Typography
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 4,
          WebkitBoxOrient: "vertical",
        }}
        className="standart_lg_text"
      >
        {content?.row?.workRequirementsDetails}
      </Typography>
    </Tooltip>
  );
};
export const SelectSimpleObject = ({ setValue, isTableheader, ...props }) => {
  /* const { facilitySelectElements } = useAppSelector(
     (state) => state.facilities
   );*/
  const { isAllExist, regulation } = props;
  const [searchValue, setSearchValue] = useState("");
  const { data, refetch } = useGetFacilitySelectElementsWithSearchQuery({ name: searchValue });
  console.log("regaultion", regulation?.protectionObjectId);
  useEffect(() => {
    searchValue && refetch();
  }, [searchValue, refetch]);

  const options = useMemo(() => {
    return data
      ? isAllExist
        ? [{ id: "все", name: "все" }, ...data]
        : data //getFacilitiesOptionsGroup(data)
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    if (regulation) {
      setValue(formFields.protectionObjectId, regulation?.protectionObjectId);
    }
  }, [options]);
  return (
    <SelectGrouping
      {...props}
      setSearchValue={setSearchValue}
      IsTableheader={isTableheader}
      searchValue={searchValue}
      options={options}

      //bgcolor={bgcolor}
    />
  );
  //return <SelectSimple options={facilitySelectElements || []} {...props} />;
};
export const SelectPeriodRegulation = (props) => {
  /* const { facilitySelectElements } = useAppSelector(
     (state) => state.facilities
   );*/
  const [searchValue, setSearchValue] = useState("");
  const { data, refetch } = useGetRegulationsPeriodsQuery({ periodName: searchValue });

  useEffect(() => {
    searchValue && refetch();
  }, [searchValue, refetch]);

  const options = useMemo(() => {
    return data
      ? [...data] //getFacilitiesOptionsGroup(data)
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <SelectSimpleSearch
      sx={{ ".MuiTypography-root": { padding: 0 } }}
      {...props}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      options={options}
    />
  );
  //return <SelectSimple options={facilitySelectElements || []} {...props} />;
};
export const EquipmentKindSelect = (props) => {
  const { data: equipmentSelectElements = [] } = useGetEquipmentSystemsQuery("");

  const selectedOptions = useMemo(() => getEquipmentOptions(equipmentSelectElements), [equipmentSelectElements]);

  return <SelectMultipleChipDeletable {...props} options={selectedOptions} />;
};

export const SimpleTextCell = ({ item, type }) => {
  const content =
    type === "date"
      ? dayjs(item).format(dateFormat)
      : type === "dateTime"
        ? dayjs(item).format(dateFormatWithTimeWithoutA)
        : item;

  return <Typography>{content}</Typography>;
};

export const JobEquipmentsCell = ({ item, navigate }) => {
  return item?.map(({ id, integrationNumber, name }) => (
    <Typography key={id} className="table-row-cell-link" onClick={() => navigate(PATHS.GET_EQUIPMENT_DETAILS(id))}>
      {`${integrationNumber ? `${integrationNumber},` : ""} ${name}`}
    </Typography>
  ));
};

export const ClickableSchemaCell = ({ item }) => {
  const { floorNumber, action } = item || {};

  return (
    <Typography className="table-row-cell-link" onClick={action ? action : () => {}}>
      {floorNumber || ""}
    </Typography>
  );
};
export const ClickableEquipmentCell = ({ item }) => {
  const { name, action } = item || {};

  return (
    <Typography className="table-row-cell-link" onClick={action ? action : () => {}}>
      {name || ""}
    </Typography>
  );
};

export const SimpleHeader = ({ name }) => (
  <Box className="table-head-up_level">
    <Typography className="standart">{name || ""}</Typography>
  </Box>
);

export const EquipmentTypeIdSelectGrouping = ({ itemoption, bgcolor, setValue, ...props }) => {
  const { objectId, regulation } = props;
  const [searchValue, setSearchValue] = useState("");
  const [equipmentSelectElements, setEquipmentSelectElements] = useState([]);
  const [getEquipmentSelectElements] = useLazyGetEquipmentSelectElementsQuery();

  useEffect(() => {
    objectId &&
      getEquipmentSelectElements({ searchValue, objectId })
        .then((res) => {
          setEquipmentSelectElements(res?.data || []);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [searchValue, objectId, getEquipmentSelectElements, equipmentSelectElements]);

  const options = useMemo(() => {
    //console.log("facFireCompSelect", getEquipmentTypeIdOptionsGroup(equipmentSelectElements)[0]?.items[0])
    return itemoption
      ? [itemoption, ...getEquipmentTypeIdOptionsGroupAddEquip(equipmentSelectElements)]
      : getEquipmentTypeIdOptionsGroupAddEquip(equipmentSelectElements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentSelectElements, itemoption, setEquipmentSelectElements]);

  useEffect(() => {
    if (regulation) {
      const elem = getEquipmentTypeIdOptionsGroupAddEquip(equipmentSelectElements);
      //{...item,parentId:option.id, id: id, name: `${option.name}, ${name}` }
      const findElem = elem.find((item) => item.id == regulation.equipmentKindId.toString());

      const findElemItem = findElem
        ? findElem.items.find((item) => item.id == regulation.equipmentTypeId.toString())
        : {};
      const fullFoundItem = {
        ...findElemItem,
        parentId: findElem?.id,
        id: findElemItem?.id,
        name: `${findElem?.name}, ${findElemItem?.name}`,
      };
      setValue("equipmentKindsIds", JSON.stringify(fullFoundItem));
    }
  }, [equipmentSelectElements]);

  return (
    <SelectGrouping
      {...props}
      setSearchValue={setSearchValue}
      searchValue={searchValue}
      options={options}
      isRegulation
      renderValue={(selected) => {
        //console.log("selectedEquip",selected)
        return selected ? `${JSON.parse(selected)?.name}` : "";
      }}
      // defaultValue={getEquipmentTypeIdOptionsGroup(equipmentSelectElements)[0]?.items[0]}
      bgcolor={bgcolor}
    />
  );
};
export const EquipmentNameGroup = ({ bgcolor, setValue, ...props }) => {
  const { equipmentKindsIds, regulation, objectId } = props;
  const [searchValue, setSearchValue] = useState("");
  const searchWithRegulation = `equipmentTypeId=${regulation?.equipmentTypeId}&equipmentKindId=${regulation?.equipmentKindId}&`;
  const [equipmentSelectElements, setEquipmentSelectElements] = useState([]);
  const search =
    equipmentKindsIds && equipmentKindsIds?.length > 0
      ? `equipmentTypeId=${JSON.parse(equipmentKindsIds).id || regulation?.equipmentTypeId}&equipmentKindId=${JSON.parse(equipmentKindsIds).parentId || regulation?.equipmentKindId}&`
      : "";
  const [getEquipmentSelectElements] = useLazyGetEquipmentGroupsQuery();

  useEffect(() => {
    equipmentKindsIds &&
      getEquipmentSelectElements(
        search + `protectionObjectId=${objectId || regulation?.protectionObjectId}&` + `groupSearch=${searchValue}`
      )
        .then((res) => {
          setEquipmentSelectElements(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [searchValue, equipmentKindsIds, getEquipmentSelectElements, equipmentSelectElements]);

  const options = useMemo(() => {
    //console.log("facFireCompSelect", getEquipmentTypeIdOptionsGroup(equipmentSelectElements)[0]?.items[0])
    return equipmentSelectElements;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentSelectElements, setEquipmentSelectElements]);

  useEffect(() => {
    if (regulation && options) {
      setValue("equipmentGroupId", regulation?.equipmentGroupId);
    } else {
      setValue("equipmentGroupId", "");
    }
  }, [equipmentSelectElements]);

  return (
    <SelectSimpleSearch
      sx={{ ".MuiTypography-root": { padding: 0 } }}
      {...props}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      options={options}
    />
  );
};
export const EquipmentRegulationNameGroup = ({ bgcolor, setValue, disabled, ...props }) => {
  const { equipmentKindsIds, equipmentGroupId, regulation } = props;
  const [equipmentSelectElements, setEquipmentSelectElements] = useState([]);
  const search =
    equipmentKindsIds && equipmentKindsIds?.length > 0
      ? `equipmentTypeId=${JSON.parse(equipmentKindsIds).id || regulation?.equipmentTypeId}&equipmentKindId=${JSON.parse(equipmentKindsIds).parentId || regulation?.equipmentKindId}&`
      : null;
  const [getEquipmentSelectElements] = useLazyGetStandardRegulationsQuery({
    equipmentTypeId:
      (regulation && regulation.equipmentTypeId) ||
      (equipmentKindsIds?.length && JSON.parse(equipmentKindsIds).parentId.replace("parent", "")) ||
      null,
  });

  useEffect(() => {
    if (search != "" && search != null) {
      getEquipmentSelectElements(search) //+ `regulationSearch=${searchValue}`)
        .then((res) => {
          setEquipmentSelectElements(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [search, equipmentGroupId, getEquipmentSelectElements]);

  const options = useMemo(() => {
    //console.log("facFireCompSelect", getEquipmentTypeIdOptionsGroup(equipmentSelectElements)[0]?.items[0])
    return equipmentSelectElements;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentSelectElements, setEquipmentSelectElements]);

  useEffect(() => {
    if (regulation) {
      setValue("regulationId", regulation?.regulationId);
    }
  }, [equipmentSelectElements]);
  return (
    <SelectSimple
      // itemValue="regulationId"
      disabled={disabled}
      sx={{ ".MuiTypography-root": { padding: 0 } }}
      {...props}
      // value={regulation.regulationid}
      options={options}
    />
  );
};
