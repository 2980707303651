import { Box, Input, Typography, Button } from "@mui/material";
import {
  TreeItem2Checkbox,
  TreeItem2Content,
  TreeItem2GroupTransition,
  TreeItem2Icon,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Provider,
  TreeItem2Root,
} from "@mui/x-tree-view";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import React, { useState } from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import s from "./AddEquipmentLite.module.css";
import { getEquipmentIcon } from "../../../../helpers/getEquipmentIcon";
import { getEquipmentIconTransparent } from "../../../../helpers/getEquipmentIconTransparent";
import Draggable from "../ImageSchema/Draggable";
import { DragOverlay } from "@dnd-kit/core";
import { snapCenterToCursor } from "@dnd-kit/modifiers";
import EditIcon from "@mui/icons-material/Edit";
import AccessibleIcon from "@mui/icons-material/Accessible";
import settingsIcon from "../../../../image/icons/settingsBtnBlack.png";
import editIcon from "../../../../image/icons/editBtnBlue.png";
import trashIcon from "../../../../image/icons/trashBtn.png";
import plusIcon from "../../../../image/icons/plusBlack.png";
const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  const {
    id,
    minScale,
    scale,
    itemId,
    ClickEquipment,
    item,
    label,
    activeId,
    disabled,
    isSystem,
    children,
    isGroup,
    countNonPlaced,
    equipIndex,
    countPlaced,
    equipmentTypeId,
    equipmentId,
    deleteEquip,
    canEdit,
    editEquip,
    canDelete,
    hasPlacement,
    onAddEquipment,
    isForEdit,
    onChangeName,
    onOpenEditReglament,
    filterEquipmentList,
    isKind,
    placedItems,
    hasPlacements,
    ...other
  } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });
  let isBoldGreen = false;
  let isBoldRed = false;
  if (isGroup) {
    const findPlacement = hasPlacements.find((obj) => obj.id === itemId.split("_")[1]);
    if (findPlacement)
      if (findPlacement?.hasPlacement) {
        isBoldGreen = true;
        isBoldRed = false;
      } else {
        isBoldGreen = false;
        isBoldRed = true;
      }
  }
  console.log("more_data",placedItems)
  const icon = getEquipmentIcon(equipmentTypeId)
  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...getRootProps(other)}>
        <TreeItem2Content {...getContentProps()}>
          <Box
            onClick={() => ClickEquipment(itemId)}
            sx={{
              flexGrow: 1,
              display: "flex",
              placeItems: "center",
              fontWeight: "500",
              justifyContent: "space-between",
              minHeight: isSystem ? "60px" : "36px",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", gap: "5px" }}>
              {equipIndex && (
                <Typography sx={{ alignContent: "center", fontWeight: "500" }}>{`${equipIndex}. `}</Typography>
              )}
              {(isForEdit && (
                <Input
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => onChangeName(e)}
                  value={label}
                />
              )) || <TreeItem2Label sx={{ alignContent: "center" }} {...getLabelProps()} />}
            </Box>
            {!!item?.number && (
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "500",
                  flexBasis: "15%",
                }}
              >
                {item.number}
              </Typography>
            )}
            {isGroup && (
              <Box sx={{ display: "flex" }}>
                <Typography
                  onClick={(e) => filterEquipmentList(e, itemId, true)}
                  sx={{
                    fontWeight: isBoldGreen ? "900" : "500",
                    color: "green",
                  }}
                >
                  {countPlaced}
                </Typography>
                <Typography sx={{ fontWeight: "500", margin: "0px 5px" }}>/</Typography>
                <Typography
                  sx={{ color: "red", fontWeight: isBoldRed ? "900" : "500" }}
                  onClick={(e) => filterEquipmentList(e, itemId, false)}
                >
                  {countNonPlaced}
                </Typography>
              </Box>
            )}
            <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
              {canEdit && (
                <Box
                  component="img"
                  src={editIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    editEquip();
                  }}
                  sx={{ marginRight: "" }}
                />
              )}
              {canDelete && (
                <Box
                  component="img"
                  src={trashIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteEquip();
                  }}
                  sx={{ marginRight: "" }}
                />
              )}

              {equipmentTypeId &&
                (!hasPlacement && !placedItems?.find((item) => item.eqId == equipmentId) ? (
                  scale >= minScale ? (
                    <>
                      <Draggable
                        withOverlay
                        className="schema-field-menu-items-row-icon"
                        id={itemId}
                        style={{ marginRight: "2px" }}
                        type={"fire"}
                      >
                        <img src={icon} />
                      </Draggable>
                      <DragOverlay modifiers={[snapCenterToCursor]}>
                        {activeId ? <img style={{ height: "25px" }} src={icon} /> : null}
                      </DragOverlay>
                    </>
                  ) : (
                    <img src={icon} />
                  )
                ) : (
                  <img src={getEquipmentIconTransparent(equipmentTypeId)} />
                ))}

              {!equipmentId && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "20px",
                    width: "11px",
                    justifyContent: "center",
                    padding: "0 16px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onAddEquipment();
                  }}
                >
                  <Box component="img" src={plusIcon} sx={{ height: "15px" }} />
                </Box>
              )}
              {/*isGroup && <Box
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}
                onClick={(e) => {
                  isGroup && onOpenEditReglament();
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <Box
                  component="img"
                  src={settingsIcon}
                  sx={{height:"20px"}}
                />

              </Box>*/}
              {!equipmentId && (
                <TreeItem2IconContainer {...getIconContainerProps()}>
                  <TreeItem2Icon slotProps={{ collapseIcon: <AccessibleIcon /> }} status={status} />
                </TreeItem2IconContainer>
              )}
            </Box>
          </Box>
        </TreeItem2Content>
        {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

export default CustomTreeItem;
